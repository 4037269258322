/* eslint-disable */

// modules
import { useState, useContext, createContext, useMemo } from "react";

// components
import Alert from "../components/Alert/Alert";

const AlertContext = createContext();

export const AlertProvider = ({ children }) => {
  const [variant, setVariant] = useState(null);
  const [message, setMessage] = useState(null);
  const [show, setShow] = useState(false);
  const [keep, setKeep] = useState(false);
  const [isGamePage, setIsGamePage] = useState(false);

  const value = useMemo(
    () => ({
      variant,
      message,
      show,
      keep,
      isGamePage,
      setKeep,
      setVariant,
      setMessage,
      setShow,
      setIsGamePage,
    }),
    [variant, message, show, keep, isGamePage]
  );

  return (
    <AlertContext.Provider value={value}>
      {" "}
      <Alert
        isGamePage={isGamePage}
        message={message}
        variant={variant}
        show={show}
        setShow={setShow}
        keep={keep}
      />{" "}
      {children}{" "}
    </AlertContext.Provider>
  );
};

const useAlert = () => useContext(AlertContext);
export default useAlert;
