import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { GameProvider } from "./context/game";
import { AlertProvider } from "./context/alert";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <StrictMode>
    <BrowserRouter>
      <GameProvider>
        <AlertProvider>
          <App />
        </AlertProvider>
      </GameProvider>
    </BrowserRouter>
  </StrictMode>
);
