// modules
import { useEffect, useMemo, useState } from "react";
import shareImg from "../../assets/share.png";
import { MAX_CHALLENGES } from "../../utils/config";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { formatTimer } from "../../utils/functions";
import { ArrowRight } from "../../assets/Icons";
import "./Stats.css";

// context
import useGame from "../../context/game";

// components
import ModalWrapper from "../Modal";

function isFloat(x) {
  if (x && !!(x % 1)) {
    return Number(x.toFixed(2));
  } else {
    return x;
  }
}

const Stats = ({ showStats, closeStats }) => {
  const {
    userStatsObj,
    winDistribution,
    gameOver,
    lastGame,
    guessList,
    currentGame,
    gameNo,
    gameTimer,
  } = useGame();

  const win = winDistribution?.some((val) => val === 1);

  if (lastGame) return;
  return (
    <ModalWrapper show={showStats} onClose={closeStats}>
      <h4>Statistics</h4>
      <div className="stats-info">
        <div>
          <span>{userStatsObj?.totalTries ? userStatsObj.totalTries : 0}</span>
          <span>Total tries</span>
        </div>
        <div>
          <span>
            {userStatsObj?.successRate ? isFloat(userStatsObj.successRate) : 0}%
          </span>
          <span>Success rate</span>
        </div>
        <div>
          <span>
            {userStatsObj?.currentStreak ? userStatsObj.currentStreak : 0}
          </span>
          <span>Current streak</span>
        </div>
        <div>
          <span>{userStatsObj?.bestStreak ? userStatsObj.bestStreak : 0}</span>
          <span>Best streak</span>
        </div>
      </div>
      {gameOver && winDistribution?.length === MAX_CHALLENGES && (
        <>
          <ShareButton
            stats={userStatsObj}
            gameNo={gameNo}
            currentGame={currentGame}
            guessList={guessList}
            gameTimer={gameTimer}
          />
          <h4>Guess Distribution</h4>
          <div
            className={`stats-guess_distribution ${
              !win ? "block_all_full" : ""
            } `}
          >
            {winDistribution.map((dist, idx) => (
              <div
                key={idx}
                className={`stats-guess_distribution_block ${
                  dist === 1 ? "block_full" : ""
                }`}
              >
                <span className="first">{idx + 1}</span>
                <span className="second">{dist}</span>
              </div>
            ))}
          </div>
          <GameCountdown />

          <div className="pt-3 border-t-2 border-gray-400">
            <h3 className="text-lg font-medium"> Play Another Game </h3>
            <a
              href="https://www.guesswhofootball.com/"
              rel="noopener noreferrer"
              className="nav_menu-mobile-league-wrapper mb-0"
            >
              <div className="nav_menu-mobile-league">
                <img
                  style={{ borderRadius: "12px" }}
                  src={require("../../assets/player.png")}
                  alt="Guess Who? football"
                />
                <span> Guess Who? football </span>
              </div>
              <ArrowRight />
            </a>
          </div>
        </>
      )}
    </ModalWrapper>
  );
};

export default Stats;

const GameCountdown = () => {
  const { gameEnded } = useGame();
  const [time, setTime] = useState(null);

  useEffect(() => {
    let interval;
    if (gameEnded) {
      interval = setInterval(() => {
        const midnight = new Date();
        midnight.setHours(24);
        midnight.setMinutes(0);
        midnight.setSeconds(0);
        midnight.setMilliseconds(0);

        const countdown = midnight - Date.now();
        const seconds = Math.floor(countdown / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        setTime({
          seconds: (seconds % 60).toString().padStart(2, 0),
          minutes: (minutes % 60).toString().padStart(2, 0),
          hours: hours.toString().padStart(2, 0),
        });
      }, 1000);
    }
    return () => {
      setTime(null);
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [gameEnded]);

  if (!time) return;
  return (
    <div className="stats-timer">
      <div>
        New footballer in
        <span> {`${time.hours}:${time.minutes}:${time.seconds}`}</span>
      </div>
    </div>
  );
};

const ShareButton = ({ gameNo, stats, currentGame, guessList, gameTimer }) => {
  const [success, setSuccess] = useState({
    winAt: 0,
    total: 0,
  });

  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    let winAt = stats.winDistribution.findIndex((val) => val === 1);

    if (winAt !== -1) {
      setSuccess({
        winAt: winAt + 1,
        total: stats.winDistribution.length,
      });
    } else {
      setSuccess({
        winAt: "X",
        total: stats.winDistribution.length,
      });
    }

    stats.successRate = isFloat(stats.successRate);
  }, [stats, currentGame]);

  let showDestro = useMemo(() => {
    let displayEntries = [];

    guessList.forEach((gs) => {
      let tmp = "";
      gs.guessCheck.forEach((gse) => {
        if (gse === "correct") tmp += "🟢";
        else if (gse === "present") tmp += "🟡";
        else tmp += "⚪";
      });

      displayEntries.push(tmp);
    });

    return [...displayEntries].join("\n");
  }, [guessList]);

  return (
    <div className="game-share">
      <CopyToClipboard
        text={`Football Wordle #${gameNo} ${success.winAt}/${success.total}

Total tries: ${stats.totalTries}
Success rate: ${stats.successRate}%
Current streak:${stats.currentStreak}
Best streak:${stats.bestStreak}

${showDestro}

Completed in ${formatTimer(gameTimer)}
      
${window.location.href}`}
        onCopy={() => setIsCopied(true)}
      >
        <button>
          <img src={shareImg} alt="share button" />
        </button>
      </CopyToClipboard>

      <p className={`isCopied ${isCopied ? "active" : ""}`}>
        {" "}
        {isCopied ? "Copied!" : ""}{" "}
      </p>
    </div>
  );
};
