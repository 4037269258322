// modules
import React from "react";
import classnames from "classnames";
import { MAX_CHALLENGES, REVEAL_TIME_MS } from "../../utils/config";
import { unicodeSplit } from "../../libs/words";

// context
import useGame from "../../context/game";

function Grid({ guesses, currentGuess, isRevealing, currentRowClass }) {
  const { mainWord } = useGame();

  const empties =
    guesses.length < MAX_CHALLENGES - 1
      ? Array.from(Array(MAX_CHALLENGES - 1 - guesses.length))
      : [];

  return (
    <div className="flex-grow my-2 md:my-0">
      {guesses.map((guess, i) => (
        <CompletedRow
          key={i}
          guess={guess}
          isRevealing={isRevealing && guesses.length - 1 === i}
        />
      ))}
      {guesses.length < MAX_CHALLENGES && (
        <CurrentRow
          solution={mainWord}
          guess={currentGuess}
          className={currentRowClass}
        />
      )}
      {empties.map((_, i) => (
        <EmptyRow key={i} solution={mainWord} />
      ))}
    </div>
  );
}

export function Cell({
  value,
  status,
  isRevealing,
  isCompleted,
  position = 0,
}) {
  const isFilled = value && !isCompleted;
  const shouldReveal = isRevealing && isCompleted;
  const animationDelay = `${position * REVEAL_TIME_MS}ms`;

  const classes = classnames(
    "w-14 h-14 border-solid border-2 flex items-center justify-center mx-0.5 text-4xl font-bold rounded text-white",
    {
      "border-slate-600": !status,
      "border-slate-100": value && !status,
      "absent shadowed border-slate-700": status === "absent",
      "correct shadowed bg-green-500 border-green-500": status === "correct",
      "present shadowed bg-yellow-500 border-yellow-500": status === "present",
      "cell-fill-animation": isFilled,
      "cell-reveal": shouldReveal,
    }
  );

  return (
    <div className={classes} style={{ animationDelay }}>
      <div className="letter-container select-none" style={{ animationDelay }}>
        {value}
      </div>
    </div>
  );
}

function CompletedRow({ guess, isRevealing }) {
  const statuses = guess.guessCheck;
  const splitGuess = unicodeSplit(guess.guessWord);

  return (
    <div className="flex justify-center mb-1">
      {splitGuess.map((letter, i) => (
        <Cell
          key={i}
          value={letter}
          status={statuses[i]}
          position={i}
          isRevealing={isRevealing}
          isCompleted
        />
      ))}
    </div>
  );
}

function CurrentRow({ guess, className, solution }) {
  const splitGuess = unicodeSplit(guess);
  const emptyCells = Array.from(Array(solution.length - splitGuess.length));
  const classes = `flex justify-center mb-1 ${className}`;

  return (
    <div className={classes}>
      {splitGuess.map((letter, i) => (
        <Cell key={i} value={letter} />
      ))}
      {emptyCells.map((_, i) => (
        <Cell key={i} />
      ))}
    </div>
  );
}

function EmptyRow({ solution }) {
  const emptyCells = Array.from(Array(solution.length));

  return (
    <div className="flex justify-center mb-1">
      {emptyCells.map((_, i) => (
        <Cell key={i} />
      ))}
    </div>
  );
}

export default Grid;
