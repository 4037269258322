// modules
import React, { useEffect, useState } from "react";
import classnames from "classnames";
import { unicodeSplit } from "../../libs/words";
import { REVEAL_TIME_MS } from "../../utils/config";

// context
import useGame from "../../context/game";

let ENTER_TEXT = "ENTER";
let DELETE_TEXT = "DELETE";

function Keyboard({ onEnter, onDelete, onChar, isRevealing }) {
  const { guessDistribution, mainWord } = useGame();
  const [keyStatuses, setKeyStatuses] = useState({});

  useEffect(() => {
    const listener = (e) => {
      if (e.code === "Enter") {
        e.preventDefault();
        onEnter();
      } else if (e.code === "Backspace") {
        e.preventDefault();
        onDelete();
      } else {
        const key = e.key.toUpperCase();
        // TODO: check this test if the range works with non-english letters
        if (key.length === 1 && key >= "A" && key <= "Z") {
          onChar(key);
        }
      }
    };
    window.addEventListener("keydown", listener);
    return () => {
      window.removeEventListener("keydown", listener);
    };
  }, [onEnter, onDelete, onChar]);

  useEffect(() => {
    const charObj = {};
    const splitSolution = unicodeSplit(mainWord);

    guessDistribution.forEach((word) => {
      unicodeSplit(word).forEach((letter, i) => {
        if (!splitSolution.includes(letter)) {
          // make status absent
          return (charObj[letter] = "absent");
        }

        if (letter === splitSolution[i]) {
          //make status correct
          return (charObj[letter] = "correct");
        }

        if (charObj[letter] !== "correct") {
          //make status present
          return (charObj[letter] = "present");
        }
      });
    });

    setKeyStatuses(charObj);
  }, [guessDistribution, mainWord]);

  const onClick = (value) => {
    if (value === "ENTER") {
      onEnter();
    } else if (value === "DELETE") {
      onDelete();
    } else {
      onChar(value);
    }
  };

  return (
    <div className="notranslate pt-4">
      <div className="flex justify-center mb-1">
        {["Q", "W", "E", "R", "T", "Y", "U", "I", "O", "P"].map((key) => (
          <Key
            status={keyStatuses[key]}
            value={key}
            key={key}
            onClick={onClick}
            isRevealing={isRevealing}
          />
        ))}
      </div>
      <div className="flex justify-center mb-1">
        {["A", "S", "D", "F", "G", "H", "J", "K", "L"].map((key) => (
          <Key
            status={keyStatuses[key]}
            value={key}
            key={key}
            onClick={onClick}
            isRevealing={isRevealing}
          />
        ))}
      </div>
      <div className="flex justify-center">
        <Key width={65.4} value="ENTER" onClick={onClick}>
          {ENTER_TEXT}
        </Key>
        {["Z", "X", "C", "V", "B", "N", "M"].map((key) => (
          <Key
            status={keyStatuses[key]}
            value={key}
            key={key}
            onClick={onClick}
            isRevealing={isRevealing}
          />
        ))}
        <Key width={65.4} value="DELETE" onClick={onClick}>
          {DELETE_TEXT}
        </Key>
      </div>
    </div>
  );
}

function Key({ children, status, width = 40, value, onClick, isRevealing }) {
  const { mainWord } = useGame();
  const keyDelayMs = REVEAL_TIME_MS * mainWord.length;
  const classes = classnames(
    "flex items-center justify-center rounded mx-0.5 text-xs font-bold cursor-pointer select-none text-white",
    {
      "transition ease-in-out": isRevealing,
      "bg-slate-600 md:hover:bg-slate-800": !status,
      "bg-slate-800 text-white": status === "absent",

      "bg-green-500 hover:bg-green-600 active:bg-green-700 text-white":
        status === "correct",
      "bg-yellow-500 hover:bg-yellow-600 active:bg-yellow-700 text-white":
        status === "present",
    }
  );
  const styles = {
    transitionDelay: isRevealing ? `${keyDelayMs}ms` : "unset",
    width: `${width}px`,
    height: "58px",
  };

  const handleOnClick = (e) => {
    e.preventDefault();
    onClick(value);
  };

  return (
    <button
      style={styles}
      aria-label={`${value} ${status}`}
      className={classes}
      onClick={handleOnClick}
    >
      {children || value}
    </button>
  );
}

export default Keyboard;
