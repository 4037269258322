// modules
import { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import "./Alert.css";
import classnames from "classnames";

const Alert = ({ isGamePage, message, variant, show, setShow, keep }) => {
  const classes = classnames({
    "bg-rose-500 text-white": variant === "error",
    "bg-blue-500 text-white": variant === "success",
  });

  const [tOut, setTOut] = useState();

  useEffect(() => {
    clearTimeout(tOut);

    if (show && !keep) {
      setTOut(
        setTimeout(() => {
          setShow(false);
          clearTimeout(tOut);
        }, 3000)
      );
    }

    return () => {
      if (tOut) clearTimeout(tOut);
    };
    // eslint-disable-next-line
  }, [show]);

  return createPortal(
    <div
      className={`alert ${isGamePage ? "game-page" : ""} select-none ${
        keep ? "absolute" : "fixed"
      } ${show ? "show" : ""}`}
    >
      <div
        className={`w-max p-2 rounded-lg text-sm md:text-base font-medium ${classes}`}
      >
        {message}
      </div>
    </div>,
    document.getElementById("alert-portal")
  );
};

export default Alert;
