import { LEAGUES } from "./constants";

export const getSecondsBetweenDates = (startDate, endDate) =>
  (new Date(endDate).getTime() - new Date(startDate).getTime()) / 1000;

export const hasDateChanged = (startDate, endDate) =>
  new Date(startDate).toLocaleDateString() !==
  new Date(endDate).toLocaleDateString();

export const padZeros = (val, count) =>
  typeof val === "string"
    ? val.padStart(count, 0)
    : val.toString().padStart(count, 0);

export const daysUntilToday = (_date) =>
  (new Date().getTime() - new Date(_date).getTime()) / (1000 * 3600 * 24);

export const getColorForLeague = (id) => {
  switch (id) {
    case LEAGUES["manchester-united"]:
      return {
        backgroundImage: "none",
        backgroundColor: "#DA291C",
      };
    case LEAGUES["manchester-city"]:
      return {
        backgroundImage: "none",
        backgroundColor: "#6CABDD",
      };

    default:
      return {
        backgroundImage: `linear-gradient(
        to right,
        rgb(236, 70, 98),
        rgb(236, 70, 98),
        yellow,
        yellow,
        rgb(19, 177, 19),
        rgb(0, 153, 255),
        rgb(0, 153, 255),
        blue
      )`,
      };
  }
};

export const formatTimer = (time) => {
  if (!time) {
    return "00:00";
  }

  const minutes = Math.floor((time / 60) % 60)
    .toFixed(0)
    .padStart(2, "0");
  const seconds = (time % 60).toFixed(0).padStart(2, "0");
  if (time / 60 >= 60) {
    const hours = Math.floor(time / 60 / 60)
      .toFixed(0)
      .padStart(2, "0");

    return `${hours}:${minutes}:${seconds}`;
  }

  return `${minutes}:${seconds}`;
};
