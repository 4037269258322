// modules
import React from "react";
import { Link } from "react-router-dom";
import { TikTokIcon, TwitterIcon } from "../assets/Icons";
import "./Footer.css";

// context
import useGame from "../context/game";

const Footer = () => {
  const { setShowContactForm } = useGame();

  const openContactForm = (e) => {
    e.preventDefault();
    setShowContactForm(true);
  };

  return (
    <footer>
      <div className="container space-y-[0.75rem] md:space-y-0">
        <div className="footer-social_links space-x-[1rem]">
          <a
            href="https://www.tiktok.com/@guesswhofootball.com"
            target="_blank"
            rel="noreferrer"
            aria-label="Twitter"
          >
            <TikTokIcon />
          </a>
          <a
            href="https://twitter.com/GuessWhoFootbal"
            target="_blank"
            rel="noreferrer"
            aria-label="Tiktok"
          >
            <TwitterIcon />
          </a>
        </div>
        <div className="footer-content space-y-[0.5rem] md:space-y-[1rem]">
          <p>&copy; Copyright Football Wordle</p>
          <p>
            All brands and logos used are for illustrative purposes only to
            enable players to use these free games
          </p>
          <p>
            <Link to="/privacy-policy" aria-label="privacy policy">
              Privacy Policy
            </Link>{" "}
            |{" "}
            <Link
              to="#contact"
              aria-label="contact us"
              onClick={openContactForm}
            >
              Contact Us
            </Link>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
